<template>
	<div>
		<ContentHeader title="Data supplier" subTitle="Tambah Data Supplier" url="/data-supplier" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
          <div class="col">
          	<div class="card">
          		<div class="card-header row">
          			<h3 class="card-title">Input Data Supplier</h3>
          		</div>
          		<div class="card-body row">
          			<div class="col-lg-2"></div>
          			<div class="col-lg-8">
          				<form @submit.prevent="prosesTambahSupplier">
	          				<div class="px-lg-2 ">
	          					<div class="form-group">
			                    <label for="kode">Kode <span class="text-danger">*</span></label>
			                    <input type="text" class="form-control" id="kode" autocomplete="off" disabled="" name="kode" required="" v-model="supplier.kode">
			                  </div>
			                  <div class="form-group">
			                    <label for="nama">Nama <span class="text-danger">*</span></label>
			                    <input type="text" name="name" id="name" v-model="supplier.nama" class="form-control">
			                  </div>
				                <div class="form-group">
			                    <label for="alamat">Alamat <span class="text-danger">*</span></label>
			                    <textarea class="form-control" style="height: 100px;" id="alamat" required="" name="alamat" v-model="supplier.alamat"></textarea>
			                  </div>
			                  <div class="form-group">
			                    <label for="telepon">Telepon <span class="text-danger">*</span></label>
			                    <input type="tel" class="form-control" id="telepon" autocomplete="off" name="telepon" required="" v-model="supplier.telepon">
			                  </div>
			                </div>
			                <button type="submit" class="btn btn-primary d-flex align-items-center ml-2 mt-4">
								<div>Submit</div>	
								<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
								  <span class="sr-only">Loading...</span>
								</div>
							</button>
	          			</form>
          			</div>
          			<div class="col-lg-2"></div>
          		</div>
          	</div>
          </div>
		    </div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'

	import { ref, reactive, computed, onMounted } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import router from '@/router'
	import Swal from 'sweetalert2'
	import tambah_nol from '@/tambah_nol.js'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup () {
			const loading = ref(false)
    		const user = computed(() => store.getters['auth/user'])
			const supplier = reactive({
				"cabang_id" : user.value.cabang_id,
				"kode" : "",
				"nama" : "",
				"alamat" : "",
				"telepon" : ""
			})

			const cabang = ref([])
			const index_cabang = ref('')

			const getCabang = async () => {
				let { data } = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

				if (data == 'kosong') {
					generateKode()
				} else {
					cabang.value = data
					generateKode()
				}
			}

			const generateKode = () => {
				let nomor_urut = ref('')
				let kode_supplier = `SUP${cabang.value.kode_cabang}`
				axios.get(`api/supplier/getForGenerateCode/${kode_supplier}`)
		    	.then((response) => {
		    		console.log(response.data)
		    		if(response.data == 'kosong'){
		    			nomor_urut.value = tambah_nol(1)
		    			supplier.kode = `SUP${cabang.value.kode_cabang}${nomor_urut.value}`
		    		} else {
		    			let str = response.data
						let length = str.length
						let nlength = length - 3
						let id = str.slice(nlength, length) 
		    			id = Math.abs(id)
		    			nomor_urut.value = tambah_nol(id + 1)
		    			supplier.kode = `SUP${cabang.value.kode_cabang}${nomor_urut.value}`
		    		}
		    	})
		    	.catch((error) => {
		    		console.log(error)
		    	})
			}



			const prosesTambahSupplier = () => {
				loading.value = true
				axios.post('api/supplier/create', supplier)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Menghapus Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
					console.log(response)
					router.replace('/data-supplier')
				})
				.catch((error) => {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal menambahkan data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})	
					loading.value = false
					console.log(error)
				})
			}

			onMounted(() => {
				getCabang()
			})

			return {
				loading, supplier, prosesTambahSupplier, cabang, index_cabang, generateKode
			}
		}
	}
</script>